<template>
    <div class="shop-employee">
        <a-tabs v-model:activeKey="active">
            <a-tab-pane key="1" tab="员工信息">
                <a-table size="small" :pagination="false" row-key="user_id" :data-source="employee.list" :columns="[
                    {title:'ID',dataIndex:'user_id'},
                    {title:'登录账号',dataIndex:'mobile', slots: { customRender: 'mobile' }},
                    {title:'用户名',dataIndex:'name', slots: { customRender: 'name' }},
                    {title:'状态',dataIndex:'status', slots: { customRender: 'status' }},
                    {title:'操作',dataIndex:'action', slots: { customRender: 'action' }},
                ]" >
                    <template #mobile="{record}">
                        <span>{{ record.mobile }}@{{ employee.master_name}}</span>
                    </template>
                    <template #name="{ record }">
                        <a-tooltip>
                            <template #title>
                                <div>创建时间：{{record.create_time}}</div>
                                <div>最后登录时间：{{record.login_time}}</div>
                                <div>最后登录IP：{{record.last_login_ip}}</div>
                            </template>
                            <span>{{ record.name }}@{{ employee.master_name}}</span>
                        </a-tooltip>
                    </template>
                    <template #status="{ record }">
                        <a-tooltip title="点击切换员工状态">
                            <a-tag class="pt" :color="record.status == 1 ? '#00CC66':'#FF6633'" @click="changeEmployeeStatus(record)">
                                {{ record.status ? "正常":"已禁用" }}
                            </a-tag>
                        </a-tooltip>
                    </template>
                    <template #action="{ record }">
                        <a-button type="link" size="small" @click="showEmployee(record,'user')">编辑</a-button>
                        <a-button type="link" size="small" @click="showEmployee(record,'role')">权限</a-button>
                        <a-button type="link" size="small" @click="showEmployee(record,'shop')">店铺</a-button>
                        <a-button type="link" size="small" @click="showPassword(record.user_id)">修改密码</a-button>
                    </template>
                </a-table>
            </a-tab-pane>
            <a-tab-pane key="2" tab="角色管理">
                <a-table
                    :data-source="roleList"
                    :pagination="false"
                    size="middle"
                    rowKey="id"
                    style="margin: 12px 0;"
                    :columns="[
                        { dataIndex: 'id', title: 'ID' },
                        { dataIndex: 'title', title: '名称' },
                        { dataIndex: 'user.name', title: '创建者' },
                        { dataIndex: 'create_time', title: '创建时间' },
                        { dataIndex: 'update_time', title: '最后更新时间' },
                        { dataIndex: 'action', title: '操作', slots: { customRender: 'action' },}
                    ]"
                >
                    <template #action="{ record }">
                        <a-space>
                            <a-button size="small" type="link" @click="showRole(record.id)">编辑</a-button>
                            <a-button size="small" type="link" @click="deletRole(record.id)">删除</a-button>
                        </a-space>
                    </template>
                </a-table>
            </a-tab-pane>

            <template #tabBarExtraContent>
                <a-space>
                    <a-button type="primary" v-if="active==1" @click="showEmployee(0,'user')">
                        <i class="ri-add-line ri-btn"></i>添加员工
                    </a-button>
                    <a-button type="primary" v-if="active==2" @click="showRole(0)">
                        <i class="ri-add-line ri-btn"></i>添加角色
                    </a-button>
                    <a-button @click="$router.go(-1)"><i class="ri-arrow-go-back-line ri-btn"></i>返回</a-button>
                </a-space>
            </template>
        </a-tabs>

        <a-drawer title="添加/编辑角色" :width="900" :visible="show.role" @close="show.role = false" >
			<a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }">
                <a-form-item label="角色名称"><a-input v-model:value="roleForm.title" /></a-form-item>
                <a-form-item label="分配权限">
                    <a-tree
                        v-if="menu && menu.length > 0"
                        checkable
                        defaultExpandAll
                        :tree-data="menu"
                        :defaultCheckedKeys="roleForm.menus"
                        :show-line="true"
                        :replaceFields="{ children: 'children', title: 'menu_name', key: 'menu_id' }"
                        v-model:checkedKeys="roleForm.menus"
                    ></a-tree>
                </a-form-item>
            </a-form>
			<div class="drawer-footer">
				<a-space>
					<a-button type="primary" @click="saveRole">保存</a-button>
					<a-button @click="show.role = false">取消</a-button>
				</a-space>
			</div>
		</a-drawer>

        <a-modal v-model:visible="show.employee" title="添加员工" @ok="saveEmployee" width="700px" @cancel="show.employee = false">
            <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }">
                <div v-if="employeeForm.type =='user'">
                    <a-form-item label="用户名">
                        <a-input v-model:value="employeeForm.name" :addon-after="'@'+$store.state.username"></a-input>
                    </a-form-item>
                    <a-form-item label="手机号">
                        <a-input v-model:value="employeeForm.mobile" addon-before="+86"></a-input>
                    </a-form-item>
                    <a-form-item label="密码" v-if="!employeeForm.user_id">
                        <a-input v-model:value="employeeForm.password"></a-input>
                    </a-form-item>
                </div>
                <a-form-item label="分配角色" v-if="employeeForm.type=='role'">
                    <a-checkbox-group v-model:value="employeeForm.group_ids">
                        <a-checkbox :value="item.id" v-for="(item, index) in roleList" :key="index">{{ item.title }}</a-checkbox>
                    </a-checkbox-group>
                </a-form-item>
                <a-form-item label="选择店铺" v-if="employeeForm.type=='shop'">
                    <a-checkbox-group v-model:value="employeeForm.program_ids">
                        <a-checkbox :value="item.program_id" v-for="(item, index) in shop" :key="index">
                            {{ item.program_name }} <span class="f12">(剩余：{{item.end_days}}天)</span>
                        </a-checkbox>
                    </a-checkbox-group>
                </a-form-item>
            </a-form>
        </a-modal>

        <a-modal v-model:visible="show.password" title="修改密码" width="600px" @ok="savePassWord" @cancel="show.password = false">
			<a-form ref="form" :model="passwordForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="新密码"><a-input v-model:value="passwordForm.password"></a-input></a-form-item>
				<a-form-item label="再次新密码"><a-input v-model:value="passwordForm.re_password"></a-input></a-form-item>
			</a-form>
		</a-modal>
    </div>
</template>
<script>
import { reactive, ref, toRefs } from 'vue'
import authModel from '@/api/saas/auth.js'
import appModel from '@/api/saas/app.js'
import userModel from '@/api/user.js'
export default {
    setup() {
        const active = ref("1")
        const _d = reactive({
            employee:{
                list:[],
                page:1,
                limit:10,
                count:0,
                master_name:"",
            },
            roleList:[],
            menu:[],
            shop:[],
            roleForm:{
                title:"",
                menus:[],
                status:1
            },
            employeeForm:{
                type:"user",
                name:"",
                mobile:"",
                password:"",
                group_ids:[],
                program_ids:[],
            },
            passwordForm:{
                user_id:0,
                password:"",
                re_password:""
            },
            show:{
                role:false,
                employee:false,
                password:false
            },
        })
        getEmployeeList(1,10)
        getRoleList()
       

        function getEmployeeList(page,limit){
            authModel.getEmployee(page,limit,null,res=>{
                _d.employee.list = res.data
                _d.employee.count = res.page.totalCount
                _d.employee.master_name = res.master_name
            })
        }

        function getRoleList(){
            authModel.getRole(1,999,res=>{
                _d.roleList = res.list
            })
        }

        function showRole(id){
            if( _d.menu.length == 0){
                authModel.getAuthMenu(res=>{
                    _d.menu = res.farm_admin.menu
                })
            }
            if( id ){
                authModel.getRoleDetail(id,res=>{
                    let me = []
                    res.menus.forEach(item=>{
                        me.push(parseInt(item))
                    })
                    _d.roleForm = {
                        id:res.id,
                        title:res.title,
                        menus:me,
                        status:res.status,
                    }
                })
            }else{
                _d.roleForm = {
                    title:"",
                    menus:[],
                    status:1,
                }
            }
            _d.show.role = true
        }

        function saveRole(){
            let data = {..._d.roleForm}
            if( !data.id ) delete data.id
            authModel.addOrEditRole(data,()=>{
                _d.show.role = false
                getRoleList()
            })
        }

        function deletRole(id){
            authModel.deleteRole(id,()=>{
                getRoleList()
            })
        }

        function showEmployee(data,type){
            _d.employeeForm.type = type
            if( _d.shop.length == 0 && type == 'shop'){
                appModel.getProgramList(1,1,999,{},(res)=>{
                    _d.shop = res.list
                })
            }
            
            if( data ){
                _d.employeeForm.user_id = data.user_id

                if( type == 'user'){
                    _d.employeeForm.name = data.name
                    _d.employeeForm.mobile = data.mobile
                }

                if( type == "role" ){
                    userModel.getUserDetail(data.user_id).then(res=>{
                        _d.employeeForm.group_ids = res.access
                    })
                }

                if( type == 'shop' ){
                    _d.employeeForm.program_ids = []
                    authModel.getEmployeeShop(data.user_id,res=>{
                        res.forEach(item=>{
                            _d.employeeForm.program_ids.push(item.program_id)
                        })
                    })
                }
            }

            _d.show.employee = true
        }

        function saveEmployee(){
            if( _d.employeeForm.type =='user' ){
                authModel.addOrEditEmployee('add',_d.employeeForm,()=>{
                    _d.show.employee = false
                    getEmployeeList(_d.employee.page,_d.employee.limit)
                })
            } 

            if( _d.employeeForm.type =='role' ){
                authModel.allocationRole(_d.employeeForm.user_id,_d.employeeForm.group_ids,()=>{
                    _d.show.employee = false
                    getEmployeeList(_d.employee.page,_d.employee.limit)
                })
            }

            if( _d.employeeForm.type == 'shop' ){
                authModel.setEmployeeShop(_d.employeeForm.user_id,_d.employeeForm.program_ids,()=>{
                    _d.show.employee = false
                    getEmployeeList(_d.employee.page,_d.employee.limit)
                })
            }
        }

        function changeEmployeeStatus(data){
            let status = data.status == 1 ? 0 : 1
            authModel.changeEmployeeStatus(data.user_id,status,()=>{
                getEmployeeList(_d.employee.page,_d.employee.limit)
            })
        }
        function showPassword(user_id){
            _d.show.password = true 
            _d.passwordForm = {
                user_id,
                password:"",
                re_password:""
            }
        }
        function savePassWord(){
            authModel.addOrEditEmployee('password',_d.passwordForm,()=>{
                _d.show.password = false
                getEmployeeList(_d.employee.page,_d.employee.limit)
            })
        }

        return{
            active,
            ...toRefs(_d),
            getEmployeeList,
            showRole,
            saveRole,
            deletRole,
            saveEmployee,
            showEmployee,
            changeEmployeeStatus,
            showPassword,
            savePassWord
        }
    },
}
</script>
<style lang="scss" scoped>
.shop-employee{
    background: #fff;
    padding: 24px;
}
.drawer-footer{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
    z-index: 1;
}
</style>